import { makeAutoObservable } from 'mobx'
import moment from 'moment'

import {
  ISubscription,
  IEntitlements,
  CreationMethod,
  SubscriptionStatus,
  Tier,
  IUser,
  Term,
  Productable,
  ProductableType,
  Venue,
  IIntegration,
  IntegrationType,
} from '@app/interfaces/shared'
import { SUBSCRIPTION_STATUS } from '@app/lib/constants'
import RootStore from '@app/stores/RootStore'
import Permissions from '@app/stores/models/Permissions'
import CurrentUser from '@app/stores/models/CurrentUser'
import Subscriptions from '@app/lib/api/Subscriptions'
import Members from '@app/lib/api/Members'
import Member from './Member'
import Location from './Location'

export default class Subscription {
  rootStore: RootStore
  id: number
  complimentaryPremiumEndAt?: string
  complimentaryPremiumActive: boolean
  complimentaryPremiumExpired: boolean
  createdAt: string
  entitlements: IEntitlements
  fusebillCustomerId?: number
  fusebillSubscriptionId?: number
  maxGenericItemsExceeded: boolean
  maxUsersExceeded: boolean
  salesforceAccountId: string
  scheduledMigrationDate: string | null
  source: CreationMethod
  status: SubscriptionStatus
  planInterval: Term
  tier: Tier
  trialStartAt: string | null
  trialEndAt: string | null
  trialConvertedAt: string | null
  userId: number
  users: IUser[] = []
  members: Member[] = []
  productable: Productable
  productableType: ProductableType
  demo: boolean
  enterprise: boolean
  permissions: Permissions
  _venue: Location | null = null
  complimentaryPremiumAccessCount: number
  integrations: IIntegration[]

  constructor(
    rootStore: RootStore,
    subscription: ISubscription,
    options = {
      isCurrentSubscription: false,
    },
  ) {
    this.rootStore = rootStore
    this.set({
      ...subscription,
      planInterval: subscription.planInterval?.toUpperCase() as Term,
      members: subscription.members.map((member) => new Member(member)),
    })
    this.permissions = new Permissions(this)
    if (this.isVenue) {
      this._venue = new Location(this, this.productable as Venue)
    }

    if (options.isCurrentSubscription) {
      document.title = `Untappd - ${subscription.productable.name}`
      this.updatePendoAccount()
    }

    makeAutoObservable(this, { rootStore: false })
  }

  set = (params: Partial<ISubscription>) => {
    Object.assign(this, params)
  }

  get daysLeftInTrial(): number {
    const today = moment().startOf('day')
    const trialEndAt = moment(this.trialEndAt).startOf('day')
    const diff = trialEndAt.diff(today, 'd')

    return diff > 0 ? diff : 0
  }

  get baseSubscriptionUrl(): string {
    return `/venues/${this.id}`
  }

  get isTrialing(): boolean {
    return this.status === SUBSCRIPTION_STATUS.TRIALING
  }

  get isActive(): boolean {
    return this.status === SUBSCRIPTION_STATUS.ACTIVE
  }

  get isPremium(): boolean {
    return this.tier === Tier.PREMIUM
  }

  get isTrialExpired(): boolean {
    return this.isTrialing && this.daysLeftInTrial === 0
  }

  get memberByUser(): Map<number, Member> {
    return this.users.reduce(
      (memberByUser: Map<number, Member>, user: IUser) => {
        const member = this.members.find((member) => member.userId === user.id)
        if (member) memberByUser.set(user.id, member)

        return memberByUser
      },
      new Map<number, Member>(),
    )
  }

  get isInactive(): boolean {
    return !this.isTrialing && !this.isActive
  }

  get isUnpaidAccount() {
    return (
      this.status === 'pending_payment' &&
      this.source === 'self_service' &&
      !this.fusebillSubscriptionId
    )
  }

  get maxGenericItemsReached(): boolean {
    if (this.isPremium) return false
    if (this.entitlements.maxGenericItems < 0) return false
    return this.venue.genericItemsCount >= this.entitlements.maxGenericItems
  }

  toggleTier = async () => {
    Subscriptions.toggleDemoTier(this.id)
    const newTier =
      this.tier == Tier.ESSENTIALS ? Tier.PREMIUM : Tier.ESSENTIALS
    this.tier = newTier
  }

  updatePendoAccount(): void {
    const {
      source,
      status,
      planInterval,
      demo,
      enterprise,
      salesforceAccountId,
      tier,
      trialStartAt,
      trialEndAt,
      trialConvertedAt,
      venue,
    } = this

    // Update Pendo account tracking
    if (!salesforceAccountId || this.user?.impersonatingUser) return

    const {
      id: venueId,
      name,
      countryName,
      untappdVenueId,
      businessType,
    } = venue

    pendo.updateOptions({
      account: {
        id: salesforceAccountId,
        source: source.toLowerCase(),
        status: status.toLowerCase(),
        businessType: businessType?.toLowerCase() || '',
        untappdId: untappdVenueId,
        demo,
        enterprise,
        venueId,
        name,
        country: countryName,
        tier: tier.toLowerCase(),
        trialStartAt: trialStartAt || '',
        trialEndAt: trialEndAt || '',
        trialConvertedAt: trialConvertedAt || '',
        planInterval: planInterval?.toString() || '',
        canEditSite: this.permissions.canEditSite,
      },
    })
  }

  removeUser = (id: number) => {
    this.users = this.users.filter((user) => user.id !== id)
    const member = this.members.find((member) => member.userId === id)!
    Members.destroy(member.id)
    this.members = this.members.filter((member) => member.userId !== id)
  }

  isIntegratedWith(type: IntegrationType): boolean {
    return Boolean(
      this.integrations.find((integration) => integration.type === type),
    )
  }

  get squareIntegration(): IIntegration | undefined {
    return this.integrations.find(
      (integration) => integration.type === IntegrationType.SQUARE,
    )
  }

  get oznrIntegration(): IIntegration | undefined {
    return this.integrations.find(
      (integration) => integration.type === IntegrationType.OZNR,
    )
  }

  get isVenue(): boolean {
    return this.productableType === 'Location'
  }

  get user(): CurrentUser {
    const { currentUser } = this.rootStore
    if (!currentUser) throw new Error('No current user in store')
    return currentUser
  }

  get member(): Member {
    return this.members.find((member) => member.userId === this.user.id)!
  }

  get complimentaryPremiumEnded() {
    return moment().isSameOrAfter(moment(this.complimentaryPremiumEndAt))
  }

  get enabledUsers(): IUser[] {
    return this.users.filter((user) => this.memberByUser.get(user.id)?.enabled)
  }

  get typeLabel(): string {
    switch (this.productableType) {
      case 'Location':
        return 'Location'
      case 'LocalBadge':
        return 'Local Badge'
      case 'SupportTool':
      case 'SalesTool':
        return 'Tool'
      default:
        return 'Subscription'
    }
  }

  get maxUsersReached(): boolean {
    const { maxUsers } = this.entitlements
    if (maxUsers < 0) return false

    return this.enabledUsers.length >= maxUsers && this.isVenue
  }

  get primaryUser(): IUser {
    return this.users.find((user) => user.id === this.userId)!
  }

  get billingUser(): IUser | undefined {
    const userId = this.members.find(
      (member) => member.venuesCanListInvoices,
    )?.userId
    return this.users.find((user) => user.id === userId)
  }

  get venue(): Location {
    if (!this._venue) throw new Error('No venue in store')
    return this._venue
  }
}
